// import React from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// const containerStyle = {
//   width: "100%",
//   height: "500px", // Ви можете змінити висоту відповідно до ваших потреб
// };

// const center = {
//   lat: 50.4057326, // Широта
//   lng: 30.3374271, // Довгота
// };

// const GoogleMapComponent = () => {
//   return (
//     <LoadScript googleMapsApiKey="AIzaSyBajJsy6w9BWWPyygqK94Yu8HvhXWZWjDU">
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={18} // Вищий рівень зближення
//         options={{
//           streetViewControl: false,
//           mapTypeControl: false,
//         }}
//       >
//         <Marker position={center} />
//       </GoogleMap>
//     </LoadScript>
//   );
// };

// export default GoogleMapComponent;
import React from "react";

const GoogleMapComponent = () => {
  return (
    <div style={{ width: "100%", height: "500px" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d531.6983046053039!2d30.326570215878025!3d50.40796775979662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cbab5f213513%3A0x70e958a9d00fe034!2sLarinVet!5e0!3m2!1suk!2sua!4v1740078785615!5m2!1suk!2sua"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMapComponent;
